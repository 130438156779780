<template>
  <div class="cosmetics">
    <div class="desc box_padding">
      <h3>行业难题</h3>
      <p>
        在化妆品市场需求膨胀的今天，假冒伪劣现象也非常猖獗，而且企业分销渠道横向上极为广泛，纵向上层级非常多，渠道形式多样，为渠道管理带来极大不便。
      </p>
      <div class="desc_img"></div>
      <p>
        消费者分布广泛，各年龄收入阶层均涵盖，收集消费者信息难度非常大，依靠传统的人工统计与分析，防伪，防窜货，促销活动具有联动机制，重复投入人力物力很大，但是管理上容易脱节。
      </p>
      <p>
        商家需基于不同的平台进行营销，返利、促销方式落后，成本高，核销难，效能低。
      </p>
    </div>

    <div class="desc_2 box_padding">
      <h3>解决方案</h3>
      <h3></h3>
      <div class="desc_img"></div>
      <p>
        商品通过搭建美安佳全周期追溯系统，连接产品、产地、检验、监管和消费各个环节；层层把控每个环节，确保产品质量，进而得到消费者的认可。
      </p>
      <p>
        根据各自监管需求采集数据，监控产品流向，应包含追溯数据获取、数据统计、数据分析、智能预警、召回管理、信息发布等功能。
      </p>
      <p>
        来源可追溯、责任可追究、去向可追踪、生产可记录、品牌产品质量保障、塑造产品价值、适用于各个行业、打造全产业链追溯。意外发生时，溯源系统还可以责任到人，及时召回有问题的产品，缩减受损范围。
      </p>
      <img src="~@/assets/img/product/cosmetics_info.png" />

      <p>
        商品一物一码以二维码入口，赋予每个商品独立唯一的二维码+防伪+精准吸粉+活动营销+大数据反馈功能，当消费者购买产品后，通过扫码机制让消费者主动扫描二维码，并通过扫码实现关注公众号、验证真伪、参与营销活动等；实现基于品牌与消费者双向互动关系；以此连接企业与消费者之间的客情关系维护，转化消费者为拓展市场营销实现最大化利益。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Cosmetics",
};
</script>

<style lang="less" scoped>
.cosmetics {
  .box_padding {
    padding: 50px 30px;
  }

  h3 {
    font-size: 50px;
    font-weight: 700;
  }
  p {
    font-size: 35px;
    color: #333;
    margin: 20px 0;
    text-align: justify;
  }
  span {
    font-size: 35px;
    font-weight: 500;
  }
  img {
    width: 100%;
    border-radius: 10px;
    height: 400px;
    border: 2px solid #f0f0f0;
  }
  .desc_img {
    width: 100%;
    height: 400px;
    border: 2px solid #f0f0f0;
    background: url("~@/assets/img/product/cosmetics_1.jpg") no-repeat
      center/cover;
    border-radius: 10px;
    margin: 10px 0;
  }
  .desc_2 {
    background-color: #f7f7f7;
    .desc_img {
      height: 250px;
      background: url("~@/assets/img/banner/cosmetics.png") no-repeat
        center/cover;
    }
  }
}
</style>